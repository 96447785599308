import { createAction, props } from '@ngrx/store';
import { Session } from '@supabase/supabase-js';

export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string }>(),
);
export const loginSuccess = createAction(
  '[Auth] Login success',
  props<{ session: Session }>(),
);
export const loginFailure = createAction(
  '[Auth] Login failure',
  props<{ error: string }>(),
);
